import React, { useState } from "react";
import {postImageUrl} from "../UrlPaths";
import {Link} from "react-router-dom";
import moment from "moment";
function SeriesScoreCard(props) {

  let team_one;
  let team_two;
  let team_one_flag;
  let team_two_flag;
  let winning_team_name = "";
  let test;

  props.dataList.display_order_1 != null || props.dataList.display_order_1 != "" ? team_one = props.dataList.display_order_1 : team_one = props.dataList.team_A;

  if(team_one === props.dataList.team_A){
    // if(props.pageType == 'scorePage'){
    //   team_one = props.dataList.team_A_full_name
    //   team_two = props.dataList.team_B_full_name;
    // }else{
      team_one = props.dataList.team_A_short_name
      team_two = props.dataList.team_B_short_name;
    // }
    
    team_one_flag = props.dataList.team_A_flag_path;
    team_two_flag = props.dataList.team_B_flag_path;

  } else{
    // if(props.pageType == 'scorePage'){
    //   team_one = props.dataList.team_B_full_name;
    //   team_two = props.dataList.team_A_full_name;
    // }else{
      team_one = props.dataList.team_B_short_name;
      team_two = props.dataList.team_A_short_name;
    // }
   
    team_one_flag = props.dataList.team_B_flag_path;
    team_two_flag = props.dataList.team_A_flag_path;
  };

  props.dataList.winning_team == props.dataList.team_A ? winning_team_name = props.dataList.team_A_short_name  : test="" ;
  props.dataList.winning_team == props.dataList.team_B ? winning_team_name = props.dataList.team_B_short_name : test='';


  const getNumberText = (number) => {
    const lastDigit = number % 10;
    const suffix =
        lastDigit === 1 && number != 11
            ? 'st'
            : lastDigit === 2 && number != 12
                ? 'nd'
                : lastDigit === 3 && number != 13
                    ? 'rd'
                    : 'th';

    return `${suffix}`;
  };

  const truncate = (input) =>
      input?.length > 18 ? `${input.substring(0, 15)}...` : input;

  return (<>
      <Link to={`/full-scorecard/${props.dataList.series_slug}/${props.dataList.id}`} style={{ color: 'black' }}>
    <div className="sidebarWidget">
      <div className="[ card ]">
        <div className="inner">
         
          {props.pageType != 'scorePage' ? (<>
            <b><p>
            {props.dataList.format != 'IPL' ? (<>
          <span className="format_label" style={{float:'right'}}>
            <span>
              {moment(props.dataList.match_date).format('D MMM YY')}
            </span>
          </span></>):(<></>)}
          
          {props.dataList.format == 'IPL' ? (<>
            {props.dataList.match_no.length > 2 ? props.dataList.match_no :(<>{props.dataList.match_no}<sup>{getNumberText(props.dataList.match_no)}</sup> Match </>)}({props.dataList.day_night}) | {moment(props.dataList.match_date).format("MMM D, YYYY")} | {truncate(props.dataList.venue)}
          </>):(<>
          {props.dataList.match_no.length > 2 ? props.dataList.match_no :(<>{props.dataList.match_no}<sup>{getNumberText(props.dataList.match_no)}</sup></>)} {props.dataList.format} | {props.dataList.series} {props.pageType == 'schedule' ? (<>| {props.dataList.venue}</>):(<> <br/><i> <small>{props.dataList.venue}</small></i></>)}
          </>)}
          </p></b>
          </>):(<></>)}

          <div className="scorecard-content">
            <div className="card_column">

              <span className={winning_team_name === team_one || winning_team_name == null || winning_team_name == "" ? "country_score_title" : "country_score_title text-muted"}>  <img src={postImageUrl+team_one_flag} width={30} style={{borderRadius:0}}/>  &nbsp; {team_one}</span>
            </div>
            <div className="card_column_right text-right">
              <span className={winning_team_name === team_one || winning_team_name == null || winning_team_name == "" ? "score_page_over_label" : "text-muted"}>{team_one == props.dataList.team_A_short_name ? props.dataList.team_A_overs == null ? "" : '('+props.dataList.team_A_overs+')' : props.dataList.team_B_overs == null ? "" : '('+props.dataList.team_B_overs+')'}</span> <span className={winning_team_name === team_one || winning_team_name == null || winning_team_name == "" ? "country_score_title" : "country_score_title text-muted"}>{team_one == props.dataList.team_A_short_name ? props.dataList.team_A_score : props.dataList.team_B_score}</span>
            </div>
          </div>

          <div className="scorecard-content">
            <div className="card_column">
              <span className={winning_team_name=== team_two || winning_team_name== null || winning_team_name == "" ? "country_score_title" : "country_score_title text-muted"}> <img src={postImageUrl+team_two_flag} width={30} style={{borderRadius:0}}/>   &nbsp; {team_two}</span>
            </div>
            <div className="card_column_right text-right">
              <span className={winning_team_name === team_two || winning_team_name == null || winning_team_name == "" ? "score_page_over_label" : "text-muted"}>{team_two == props.dataList.team_B_short_name ? (props.dataList.team_B_overs==null ? "": '('+props.dataList.team_B_overs+')') : props.dataList.team_A_overs == null ?"": '('+props.dataList.team_A_overs+')'}</span> <span className={winning_team_name === team_two || winning_team_name == null || winning_team_name == "" ? "country_score_title" : "country_score_title text-muted"}>{ team_two == props.dataList.team_B_short_name ? props.dataList.team_B_score : props.dataList.team_A_score}</span>
            </div>
          </div>
          <div  className="scorecard-content">
            <span>{props.dataList.match_results} </span>
          </div>

        </div>

      </div>
    </div>
</Link>

  </>)
}

export default SeriesScoreCard;
