import React, {useEffect, useState} from "react";
import Flag from 'react-world-flags'
import Timer from "./Timer";
import TimeAgo from "javascript-time-ago";
import en from "javascript-time-ago/locale/en";
import moment from "moment";
import {postImageUrl} from "../UrlPaths";
import {Link} from "react-router-dom";

function UpcomingScoreCard(props) {


  const getNumberText = (number) => {
    const lastDigit = number % 10;
    const suffix =
        lastDigit === 1 && number != 11
            ? 'st'
            : lastDigit === 2 && number != 12
                ? 'nd'
                : lastDigit === 3 && number != 13
                    ? 'rd'
                    : 'th';

    return `${suffix}`;
  };

  TimeAgo.addLocale(en);
  // Create a new instance
  // const timeAgo = new TimeAgo("en-GB");
  // const inSeconds = new Date(new Date(date).toLocaleString('en', {timeZone: localStorage.getItem("tz")})).getTime();
  // const minutesAgo = timeAgo.format(inSeconds - 60 * 1000);

  // console.log('ddm:',new Date(new Date(date).toLocaleString('en', {timeZone: localStorage.getItem("tz")})));
    let match_date = props.dataList.match_date +'T'+ props.dataList.match_time;
    // const millisecondsDifference = moment(match_date).format() - moment().tz("Europe/London").format();
    // const secondsDifference = millisecondsDifference / 1000;
    // var day_seconds = Math.floor(secondsDifference);

    const time2 = new Date(match_date);
    time2.setSeconds(time2.getSeconds());

    // var hours_seconds = (moment(time, 'hh:mm').format('hh') * 3600) + (moment(time, 'hh:mm').format('mm') * 60);
    // console.log('sss:',new Date(new Date().toLocaleString('en', {timeZone: localStorage.getItem("tz")})))
    // const timeCal = new Date(new Date().toLocaleString('en-GB', {timeZone: 'Europe/London'}))
    // let total = day_seconds;
    // timeCal.setSeconds(timeCal.getSeconds() + total);

    var dateText = moment(props.dataList.match_date).from(new Date());
    var startOfToday = moment().startOf('day');
    var startOfDate = moment(props.dataList.match_date).startOf('day');
    var daysDiff = startOfDate.diff(startOfToday, 'days');
    var days = {
        '0': 'today',
        '-1': 'yesterday',
        '1': 'tomorrow'
    };

    if (Math.abs(daysDiff) <= 1) {
        dateText = days[daysDiff];
    }
    const truncate = (input) =>
        input?.length > 15 ? `${input.substring(0, 12)}...` : input;

  return (<>
<Link to={`/full-scorecard/${props.dataList.series_slug}/${props.dataList.id}`}>
      <div className="">
      <article className="score [ card ]">
        <div className="inner">

      <span className="format_label" style={{backgroundColor:`${props.dataList.format_color}`,color:`${props.dataList.format == 'TEST' ? 'black':''}`}}>
        <span>
          {props.dataList.format}
        </span>
      </span>
            <p className="title">
                {props.dataList.format == 'IPL' ? (<>
                    {props.dataList.match_no.length > 2 ? props.dataList.match_no : (<>{props.dataList.match_no}<sup>{getNumberText(props.dataList.match_no)}</sup> Match</>)} ({props.dataList.day_night}) | {moment(props.dataList.match_date).format("MMM D, YYYY")} | {truncate(props.dataList.venue)}
                </>):(<>
                {props.dataList.match_no.length > 2 ? props.dataList.match_no : (<>{props.dataList.match_no}<sup>{getNumberText(props.dataList.match_no)}</sup> </>)} {props.dataList.format} | {props.dataList.series} | {truncate(props.dataList.venue)}
                </>)}
            </p>

            <div className="scorecard-content">
              <div className="card_column_upcoming">
                  <img src={postImageUrl+props.dataList.team_A_flag_path} className="match_flag"/>
                 <span className="country_score_title "> &nbsp;{props.dataList.format == 'IPL' ? (<>{props.dataList.team_A_full_name}</>):(<>{props.dataList.team_A_full_name}</>)} </span>
              </div>
              <div className="card_column_upcoming_right text-right">
                <span className="country_score_title">
                    {dateText.charAt(0).toUpperCase()+ dateText.slice(1)}
                    {/*{minutesAgo.charAt(0).toUpperCase() + minutesAgo.slice(1)}*/}
                </span>
              </div>
            </div>
          <div className="scorecard-content">
            <div className="card_column_upcoming">
                <img src={postImageUrl+props.dataList.team_B_flag_path} className="match_flag"/>
             <span className="country_score_title"> &nbsp;{props.dataList.format == 'IPL' ? (<>{props.dataList.team_B_full_name}</>):(<>{props.dataList.team_B_full_name}</>)}</span>
            </div>
            <div className="card_column_upcoming_right text-right">
              <span className="country_score_title">{moment(props.dataList.match_time, 'hh:mm').format('h:mm A')}</span>
            </div>
            </div>
          <div  className="scorecard-content">

               {props.dataList.toss != null ?(<>
                   <p className="score_result_title">{props.dataList.toss}</p>
               </>):(<>
                   <p className="score_result_title"> Match starts in &nbsp; </p>  <Timer expiryTimestamp={time2} />
               </>)}

          </div>

        </div>
        <div className="status-details d-flex">
              <span className={"match-status-"+props.dataList.status.replace(/\s+/g, '-')}>{props.dataList.status}</span>
              <div className="d-flex" style={{marginLeft:'auto',display:'flex',gap:'10px'}}>
              <span>
                <Link to={`/match-schedule/${props.dataList.series_slug}`} state={{ id:props.dataList.series_id }}><span className="series-text">Schedule</span></Link>
                </span>
                {props.dataList.points_table == 1 ? (<>
                <span>
                <Link to={`/points-table/${props.dataList.series_slug}`} state={{ id:props.dataList.series_id }}><span className="series-text">Table</span></Link>
                </span>
                </>):(<></>)}
                <span> <Link to={`/series/${props.dataList.series_slug}`} state={{ id:props.dataList.series_id }}><span className="series-text">Series</span></Link></span>
          
              </div>
           
        </div>
      </article>
      </div>
      </Link>
  </>)
}

export default UpcomingScoreCard;
