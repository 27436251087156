import React, { useState, useEffect, useMemo } from "react";
import { Helmet, HelmetProvider } from 'react-helmet-async';
import { Link, useParams, useLocation } from "react-router-dom";

import Footer from "../components/Footer";
import { useTranslate } from '../translate';
import SeriesScoreCard from "../components/SeriesScoreCard";
import SeriesUpcomingScoreCard from "../components/SeriesUpcomingScoreCard";
import NewMainHeader from "../components/NewMainHeader";

import { getMatchFullScoreData } from "../services/MatchScoreAPIServices";
import moment from "moment";
import InningBattingTable from "../components/InningBattingTable";
import InningBowlingTable from "../components/InningBowlingTable";
import InningFallWickets from "../components/InningFallWickets";
import InningNotBattingPlayers from "../components/InningNotBattingPlayers";
import PlayerOfMatch from "../components/PlayerOfMatch";
import MatchDetailsSection from "../components/MatchDetailsSection";

function MatchFullScore() {
  const seriesLocation = useLocation()

  const { slug, id } = useParams();
  const i18n = useTranslate();
  const { t } = i18n;
  const language = localStorage.getItem("rcml-lang");
  const [seriesName, setSeriesName] = useState("");
  const [matchStatus, setMatchStatus] = useState("");
  const [venue, setVenue] = useState("");
  const location = useLocation();
  sessionStorage.setItem('beforeLogin', location.pathname);
  const [isFixed, setIsFixed] = useState(false);
  const [topTitle, setTopTitle] = useState("");
  const [venueTime, setVenueTime] = useState("");
  const [pageHeading, setPageHeading] = useState("");
  const [matchNo, setMatchNo] = useState("");
  const [summaryScorecardData, setSummaryScorecardData] = useState([]);
  const [firstInningCountry, setFirstInningCountry] = useState("");
  const [secondInningCountry, setSecondInningCountry] = useState("");
  const [thirdInningCountry, setThirdInningCountry] = useState("");
  const [fourthInningCountry, setFourthInningCountry] = useState("");
  const [firstInningBattingData, setFirstInningBattingData] = useState([]);
  const [firstInningNotBattingPlayers, setFirstInningNotBattingPlayers] = useState([]);
  const [firstInningExtras, setFirstInningExtras] = useState("");
  const [firstInningScore, setFirstInningScore] = useState("");
  const [firstInningFallWickets, setFirstInningFallWickets] = useState([]);
  const [firstInningBowling, setFirstInningBowling] = useState([]);
  const [secondInningBattingData, setSecondInningBattingData] = useState([]);
  const [secondInningNotBattingPlayers, setSecondInningNotBattingPlayers] = useState([]);
  const [secondInningExtras, setSecondInningExtras] = useState("");
  const [secondInningScore, setSecondInningScore] = useState("");
  const [secondInningFallWickets, setSecondInningFallWickets] = useState([]);
  const [secondInningBowling, setSecondInningBowling] = useState([]);
  const [thirdInningBattingData, setThirdInningBattingData] = useState([]);
  const [thirdInningNotBattingPlayers, setThirdInningNotBattingPlayers] = useState([]);
  const [thirdInningExtras, setThirdInningExtras] = useState("");
  const [thirdInningScore, setThirdInningScore] = useState("");
  const [thirdInningFallWickets, setThirdInningFallWickets] = useState([]);
  const [thirdInningBowling, setThirdInningBowling] = useState([]);
  const [fourthInningBattingData, setFourthInningBattingData] = useState([]);
  const [fourthInningNotBattingPlayers, setFourthInningNotBattingPlayers] = useState([]);
  const [fourthInningExtras, setFourthInningExtras] = useState("");
  const [fourthInningScore, setFourthInningScore] = useState("");
  const [fourthInningFallWickets, setFourthInningFallWickets] = useState([]);
  const [fourthInningBowling, setFourthInningBowling] = useState([]);
  const [playerOfMatchData, setPlayerOfMatchData] = useState([]);
  const [playerOfMatch, setPlayerOfMatch] = useState([]);
  const [matchDate, setMatchDate] = useState("");
  const [matchDetails, setMatchDetails] = useState("");
  const [otherDetails, setOtherDetails] = useState("");
  const [thirdPartyMatchName, setThirdPartyMatchName] = useState("");
  const [latestInning, setLatestInning] = useState("");
  const [matchFormat, setMatchFormat] = useState("");

  const getNumberText = (number) => {
    const lastDigit = number % 10;
    const suffix =
      lastDigit === 1 && number != 11
        ? 'st'
        : lastDigit === 2 && number != 12
          ? 'nd'
          : lastDigit === 3 && number != 13
            ? 'rd'
            : 'th';

    return `${suffix}`;
  };

  useEffect(() => {

    const fetchData = async () => {
      try {
        var data = { matchId: id };
        getMatchFullScoreData(data).then((response) => {
          // console.log('response', response)
          let firstInningData = JSON.parse(response.scoreCard_inning_1_response);
          let secondInningData = JSON.parse(response.scoreCard_inning_2_response);
          let thirdInningData = JSON.parse(response.scoreCard_inning_3_response);
          let fourthInningData = JSON.parse(response.scoreCard_inning_4_response);
          let matchHeaderData = JSON.parse(response.matchHeader_response);
          let otherData = JSON.parse(response.other_response);

          if(JSON.stringify(fourthInningData).includes("batTeamDetails")){
            setLatestInning(4);
          }else if(JSON.stringify(thirdInningData).includes("batTeamDetails")){
            setLatestInning(3);
          }else if(JSON.stringify(secondInningData).includes("batTeamDetails")){
            setLatestInning(2);
          }else{
            setLatestInning(1);
          }
         
          if (otherData != null) {
            setOtherDetails(otherData);
          }
          if (JSON.stringify(firstInningData).includes("batTeamDetails")) {
            setFirstInningCountry(response.format == 'TEST' ? firstInningData.batTeamDetails.batTeamName+' 1st':firstInningData.batTeamDetails.batTeamName);
            setFirstInningBattingData(Object.values(firstInningData.batTeamDetails.batsmenData))
            setFirstInningFallWickets(Object.values(firstInningData.wicketsData))
            setFirstInningExtras(firstInningData.extrasData);
            setFirstInningScore(firstInningData.scoreDetails);
            setFirstInningBowling(Object.values(firstInningData.bowlTeamDetails.bowlersData))
          }
          if (JSON.stringify(secondInningData).includes("batTeamDetails")) {
            let inning = firstInningData.batTeamDetails.batTeamId == secondInningData.batTeamDetails.batTeamId ? ' 2nd':' 1st';
            setSecondInningCountry(response.format == 'TEST' ? secondInningData.batTeamDetails.batTeamName+inning: secondInningData.batTeamDetails.batTeamName)
            setSecondInningBattingData(Object.values(secondInningData.batTeamDetails.batsmenData))
            setSecondInningFallWickets(Object.values(secondInningData.wicketsData))
            setSecondInningExtras(secondInningData.extrasData);
            setSecondInningScore(secondInningData.scoreDetails);
            setSecondInningBowling(Object.values(secondInningData.bowlTeamDetails.bowlersData));
          }
          if (JSON.stringify(thirdInningData).includes("batTeamDetails")) {
            let inning = firstInningData.batTeamDetails.batTeamId == secondInningData.batTeamDetails.batTeamId ? ' 1st':' 2nd';
            setThirdInningCountry(response.format == 'TEST' ? thirdInningData.batTeamDetails.batTeamName+inning: thirdInningData.batTeamDetails.batTeamName)
            setThirdInningBattingData(Object.values(thirdInningData.batTeamDetails.batsmenData))
            setThirdInningFallWickets(Object.values(thirdInningData.wicketsData))
            setThirdInningExtras(thirdInningData.extrasData);
            setThirdInningScore(thirdInningData.scoreDetails);
            setThirdInningBowling(Object.values(thirdInningData.bowlTeamDetails.bowlersData));
          
          }

          if (JSON.stringify(fourthInningData).includes("batTeamDetails")) {
            let inning = ' 2nd';
            setFourthInningCountry(response.format == 'TEST' ? fourthInningData.batTeamDetails.batTeamName+inning: fourthInningData.batTeamDetails.batTeamName)
            setFourthInningBattingData(Object.values(fourthInningData.batTeamDetails.batsmenData))
            setFourthInningFallWickets(Object.values(fourthInningData.wicketsData))
            setFourthInningExtras(fourthInningData.extrasData);
            setFourthInningScore(fourthInningData.scoreDetails);
            setFourthInningBowling(Object.values(fourthInningData.bowlTeamDetails.bowlersData));
          
          }

          if (matchHeaderData != undefined && matchHeaderData != "{}") {
            setPlayerOfMatchData(Object.values(matchHeaderData.playersOfTheMatch));
            setMatchDetails(matchHeaderData);
            setThirdPartyMatchName(matchHeaderData.matchDescription)
          }

          setPageHeading(response.series + " | ");
          setTopTitle(response.team_A_full_name + " vs " + response.team_B_full_name + ", ");
          setMatchNo(response.match_no)
          setVenueTime(response.day_night == "" || response.day_night == null || response.day_night == 'null' ? (<> at {response.venue}</>) : (<>   ({response.day_night}) at {response.venue}</>));
          setSummaryScorecardData(response)
          setMatchStatus(response.status);
          setSeriesName(response.series);
          setVenue(response.venue);
          setMatchFormat(response.format);
          let timeElements = response.match_time.split(":")
          setMatchDate(moment(response.match_date).format('dddd Do MMMM YYYY') + ", " + timeElements[0] + ':' + timeElements[1])

        });
      } catch (error) {
        console.error('Error fetching data:', error);
      }
    };
    fetchData();

    // Polling interval (every 5 seconds in this example)
    const pollingInterval = setInterval(fetchData, 10000);

    // Cleanup function to clear interval when component unmounts
    return () => clearInterval(pollingInterval);


    // const handleScroll = () => {
    //   const middleSection = document.getElementById('middleSection');
    //   console.log('middleSection', middleSection);
    //   const scrollPosition = window.scrollY || window.pageYOffset;
    //   const middleSectionTop = middleSection.offsetTop;
    //   const middleSectionHeight = middleSection.offsetHeight;
    //   const viewportHeight = window.innerHeight;

    //   // Check if the top of the viewport has passed the middle section
    //   setIsFixed(scrollPosition > middleSectionTop && scrollPosition < middleSectionTop + middleSectionHeight - viewportHeight);
    // };

    // window.addEventListener('scroll', handleScroll);

    // return () => {
    //   window.removeEventListener('scroll', handleScroll);
    // };
  }, [id]);

  useEffect(() => {
    setFirstInningNotBattingPlayers([])
    setSecondInningNotBattingPlayers([])
    setThirdInningNotBattingPlayers([])
    setFourthInningNotBattingPlayers([])
    setPlayerOfMatch([]);

    firstInningBattingData.map((item, index) => (
      item.outDesc == "" ? setFirstInningNotBattingPlayers(prevItems => [...prevItems, { "batName": item.batName, "isCaptain": item.isCaptain, "isKeeper": item.isKeeper }]) : ''
    ));

    secondInningBattingData.map((item, index) => (
      item.outDesc == "" ? setSecondInningNotBattingPlayers(prevItems => [...prevItems, { "batName": item.batName, "isCaptain": item.isCaptain, "isKeeper": item.isKeeper }]) : ''
    ));

    thirdInningBattingData.map((item, index) => (
      item.outDesc == "" ? setThirdInningNotBattingPlayers(prevItems => [...prevItems, { "batName": item.batName, "isCaptain": item.isCaptain, "isKeeper": item.isKeeper }]) : ''
    ));

    fourthInningBattingData.map((item, index) => (
      item.outDesc == "" ? setFourthInningNotBattingPlayers(prevItems => [...prevItems, { "batName": item.batName, "isCaptain": item.isCaptain, "isKeeper": item.isKeeper }]) : ''
    ));

    playerOfMatchData.map((player, index) => {
      firstInningBattingData.map((bat1, index) => (
        bat1.batId == player.id && bat1.runs > 0 ? setPlayerOfMatch(prevItems => [...prevItems, { "name": player.fullName, "team": matchFormat == 'TEST' ? firstInningCountry.slice(0, -3):firstInningCountry, "marks": "" + bat1.wicketCode == "" ? "" + bat1.runs + "* (" + bat1.balls + ")" : '' + bat1.runs + " (" + bat1.balls + ")" }]) : ''
      ))

      secondInningBattingData.map((bat2, index) => (

        bat2.batId == player.id && bat2.runs > 0 ? setPlayerOfMatch(prevItems => [...prevItems, { "name": player.fullName, "team": matchFormat == 'TEST' ? secondInningCountry.slice(0, -3) : secondInningCountry, "marks": "" + bat2.wicketCode == "" ? "" + bat2.runs + "* (" + bat2.balls + ")" : '' + bat2.runs + " (" + bat2.balls + ")" }]) : ''
      ))

      thirdInningBattingData.map((bat3, index) => (
        bat3.batId == player.id && bat3.runs > 0 ? setPlayerOfMatch(prevItems => [...prevItems, { "name": player.fullName, "team": matchFormat == 'TEST' ? thirdInningCountry.slice(0, -3): thirdInningCountry, "marks": "" + bat3.wicketCode == "" ? "" + bat3.runs + "* (" + bat3.balls + ")" : '' + bat3.runs + " (" + bat3.balls + ")" }]) : ''
      ))

      fourthInningBattingData.map((bat3, index) => (
        bat3.batId == player.id && bat3.runs > 0 ? setPlayerOfMatch(prevItems => [...prevItems, { "name": player.fullName, "team": matchFormat == 'TEST' ? fourthInningCountry.slice(0, -3): fourthInningCountry, "marks": "" + bat3.wicketCode == "" ? "" + bat3.runs + "* (" + bat3.balls + ")" : '' + bat3.runs + " (" + bat3.balls + ")" }]) : ''
      ))

      firstInningBowling.map((bowling1, index) => (
        bowling1.bowlerId == player.id && bowling1.wickets > 0 ? setPlayerOfMatch(prevItems => [...prevItems, { "name": player.fullName, "team": secondInningCountry.slice(0, -3), "marks": "" + bowling1.wickets + "/" + bowling1.runs + "" }]) : ''
      ))

      secondInningBowling.map((bowling2, index) => (
        bowling2.bowlerId == player.id && bowling2.wickets > 0 ? setPlayerOfMatch(prevItems => [...prevItems, { "name": player.fullName, "team": firstInningCountry.slice(0, -3), "marks": "" + bowling2.wickets + "/" + bowling2.runs + "" }]) : ''
      ))

      thirdInningBowling.map((bowling3, index) => (
        bowling3.bowlerId == player.id && bowling3.wickets > 0 ? setPlayerOfMatch(prevItems => [...prevItems, { "name": player.fullName, "team": thirdInningCountry.slice(0, -3), "marks": "" + bowling3.wickets + "/" + bowling3.runs + "" }]) : ''
      ))
      fourthInningBowling.map((bowling4, index) => (
        bowling4.bowlerId == player.id && bowling4.wickets > 0 ? setPlayerOfMatch(prevItems => [...prevItems, { "name": player.fullName, "team": fourthInningCountry.slice(0, -3), "marks": "" + bowling4.wickets + "/" + bowling4.runs + "" }]) : ''
      ))


    });


  }, [firstInningBattingData, secondInningBattingData,thirdInningBattingData,fourthInningBattingData, firstInningBowling, secondInningBowling, thirdInningBowling, fourthInningBowling,playerOfMatchData]);


  return (
    <>
      <HelmetProvider>
        <div id="main" className="header-big">
          <Helmet>
            <title> {pageHeading}  {t('ln_sports')}</title>
          </Helmet>
          <NewMainHeader />

          <div className="container main-wrapper">
            <div className="main-content mag-content clearfix">
              <div className="row main-body" data-stickyparent>
                <div className="col-md-12">
                  <h3 className="block-title">
                    {pageHeading} &nbsp;{matchDate}
                  </h3>
                  <h5> <b>{topTitle} {thirdPartyMatchName == "" ? (<>{matchNo}<sup>{getNumberText(matchNo)} </sup> Match</>) : (<>{thirdPartyMatchName}</>)} {venueTime}</b></h5>
                  <div style={{ marginBottom: '6px' }}>
                    <span className={"match-status-" + matchStatus.replace(/\s+/g, '-')}>{matchStatus}</span>
                  </div>

                </div>
                <div className="col-md-8">
                  <section className="admag-block">

                    {matchStatus == 'UPCOMING' ? (
                      <><SeriesUpcomingScoreCard dataList={summaryScorecardData} /></>
                    ) : (<>
                      <SeriesScoreCard dataList={summaryScorecardData} pageType={'scorePage'} />
                    </>)}

                    <div className="widget tabwidget" id="middleSection" >
                      <ul className="nav nav-tabs" role="tablist" id="widget-tab" style={{ marginBottom: '10px' }}>
                        {matchStatus != 'UPCOMING' && firstInningCountry != "" ? (<>
                          <li role="presentation" className={matchStatus != 'UPCOMING' && firstInningCountry != "" ? `active` : ''}>
                            <a
                              href="#tab-scorecard"
                              aria-controls="tab-scorecard"
                              role="tab"
                              data-toggle="tab"
                            >
                              SCORECARD
                            </a>
                          </li>
                        </>) : (<></>)}

                        <li role="presentation" className={firstInningCountry == "" ? `active` : ''}>
                          <a
                            href="#tab-summary"
                            aria-controls="tab-summary"
                            role="tab"
                            data-toggle="tab"
                          >
                            SUMMARY
                          </a>
                        </li>
                        <li role="presentation">
                          <a
                            href="#tab-stats"
                            aria-controls="tab-stats"
                            role="tab"
                            data-toggle="tab"
                          >
                            STATS
                          </a>
                        </li>
                        <li role="presentation">
                          <a
                            href="#tab-news"
                            aria-controls="tab-news"
                            role="tab"
                            data-toggle="tab"
                          >
                            NEWS
                          </a>
                        </li>
                      </ul>

                      <div className="tab-content">
                        {matchStatus != 'UPCOMING' ? (<>
                          <div role="tabpanel" className="tab-pane active" id="tab-scorecard">

                            <ul className={`nav nav-tabs ${isFixed ? "fixed-section" : "absolute-section"}`} role="tablist" id="widget-tab">
                                  <li role="presentation" className={latestInning == 1 ? `active` : ''}>
                                    <a
                                      href="#tab-first-inning"
                                      aria-controls="tab-first-inning"
                                      role="tab"
                                      data-toggle="tab"
                                    >
                                      {firstInningCountry} Innings
                                    </a>
                                  </li>
                                  {secondInningCountry != "" ?(<>
                                    <li role="presentation" className={latestInning == 2 ? `active` : ''}>
                                    <a
                                      href="#tab-second-inning"
                                      aria-controls="tab-second-inning"
                                      role="tab"
                                      data-toggle="tab"
                                    >
                                      {secondInningCountry} Innings
                                    </a>
                                  </li>
                                  </>):(<></>)}
                                  {thirdInningCountry != "" ?(<>
                                  <li role="presentation" className={latestInning == 3 ? `active` : ''}>
                                    <a
                                      href="#tab-third-inning"
                                      aria-controls="tab-third-inning"
                                      role="tab"
                                      data-toggle="tab"
                                    >
                                      {thirdInningCountry} Innings
                                    </a>
                                  </li>
                                  </>):(<></>)}
                                  {fourthInningCountry != "" ?(<>
                                  <li role="presentation" className={latestInning == 4 ? `active` : ''}>
                                    <a
                                      href="#tab-fourth-inning"
                                      aria-controls="tab-fourth-inning"
                                      role="tab"
                                      data-toggle="tab"
                                    >
                                      {fourthInningCountry} Innings
                                    </a>
                                  </li>
                                  </>):(<></>)}
                            </ul>

                            <div className="tab-content">
                              <div role="tabpanel" className={latestInning == "1" ? 'tab-pane active' : 'tab-pane'} id="tab-first-inning">
                                <div className="card">
                                  <div> <h4 className="text-bold">{matchFormat == 'TEST' ? firstInningCountry.slice(0, -3): firstInningCountry}</h4> </div>
                                  <div className="card-body">
                                    <InningBattingTable inningBattingData={firstInningBattingData} inningScore={firstInningScore} inningExtras={firstInningExtras} />
                                    <InningNotBattingPlayers inningNotBattingPlayerslist={firstInningNotBattingPlayers} />
                                    <InningFallWickets inningFallWickets={firstInningFallWickets} />
                                    <InningBowlingTable inningBowling={firstInningBowling} />

                                  </div>
                                </div>
                              </div>

                              <div role="tabpanel" className={latestInning == 2 ? 'tab-pane active' : 'tab-pane'} id="tab-second-inning">
                                <div className="card">
                                  <div className="card-header"> <h4 className="text-bold">{matchFormat == 'TEST' ? secondInningCountry.slice(0, -3) : secondInningCountry}</h4></div>
                                  <div className="card-body">
                                    <InningBattingTable inningBattingData={secondInningBattingData} inningScore={secondInningScore} inningExtras={secondInningExtras} />
                                    <InningNotBattingPlayers inningNotBattingPlayerslist={secondInningNotBattingPlayers} />
                                    <InningFallWickets inningFallWickets={secondInningFallWickets} />
                                    <InningBowlingTable inningBowling={secondInningBowling} />
                                  </div>
                                </div>
                              </div>
                              <div role="tabpanel" className={latestInning == 3 ? 'tab-pane active' : 'tab-pane'} id="tab-third-inning">
                                <div className="card">
                                  <div className="card-header"> <h4 className="text-bold">{thirdInningCountry.slice(0, -3)}</h4></div>
                                  <div className="card-body">
                                    <InningBattingTable inningBattingData={thirdInningBattingData} inningScore={thirdInningScore} inningExtras={thirdInningExtras} />
                                    <InningNotBattingPlayers inningNotBattingPlayerslist={thirdInningNotBattingPlayers} />
                                    <InningFallWickets inningFallWickets={thirdInningFallWickets} />
                                    <InningBowlingTable inningBowling={thirdInningBowling} />
                                  </div>
                                </div>
                              </div>
                              <div role="tabpanel" className={latestInning == 4 ? 'tab-pane active' : 'tab-pane'} id="tab-fourth-inning">
                                <div className="card">
                                  <div className="card-header"> <h4 className="text-bold">{fourthInningCountry.slice(0, -3)}</h4></div>
                                  <div className="card-body">
                                    <InningBattingTable inningBattingData={fourthInningBattingData} inningScore={fourthInningScore} inningExtras={fourthInningExtras} />
                                    <InningNotBattingPlayers inningNotBattingPlayerslist={fourthInningNotBattingPlayers} />
                                    <InningFallWickets inningFallWickets={fourthInningFallWickets} />
                                    <InningBowlingTable inningBowling={fourthInningBowling} />
                                  </div>
                                </div>
                              </div>
                            </div>
                          </div>
                        </>) : (<></>)}
                        <div role="tabpanel" className={firstInningCountry == "" ? `tab-pane active` : 'tab-pane'} id="tab-summary">
                          <span>No summary</span>
                        </div>
                        <div role="tabpanel" className="tab-pane" id="tab-stats">No stats</div>
                        <div role="tabpanel" className="tab-pane" id="tab-news">No news</div>
                      </div>
                    </div>
                  </section>
                </div>
                <div className="col-md-4" data-stickycolumn>

                  {playerOfMatch.length > 0 ? (<>
                    <PlayerOfMatch playerOfMatch={playerOfMatch} />
                    <MatchDetailsSection matchData={matchDetails} series={seriesName} match_no={matchNo} venue={venue} otherData={otherDetails} />
                  </>) : (<>
                    <MatchDetailsSection matchData={matchDetails} series={seriesName} match_no={matchNo} venue={venue} otherData={otherDetails} />
                  </>)}


                </div>

              </div>
            </div>
          </div>
          <Footer />
        </div>

      </HelmetProvider>
    </>
  );
}
export default MatchFullScore;
