import React from "react";
import { Helmet } from "react-helmet";
import { useLocation } from "react-router-dom";
import { websiteUrl} from "../UrlPaths";

export default function HelmetMetaData(props) {
   let location = useLocation();
   let currentUrl = websiteUrl.slice(0, -1) + location.pathname;
   let quote = props.quote !== undefined ? props.description : "Win365 Sports";
   let title = props.title !== undefined ? props.title : "Win365 Sports";
   let image = props.image !== undefined ? props.image : "";
   let description = props.description !== undefined ? props.description  : "Sri Lanka's first dedicated Website and the National Newspaper focusing on sports.";
return (
 <Helmet>
     <title>{title}</title>
     <meta charset="utf-8" />
     <meta http-equiv="X-UA-Compatible" content="IE=edge" />
     <meta property="type" content="website" />
     <meta property="url" content={currentUrl} />
     <meta name="robots" content="noodp" />
     <meta property="title" content={title} />
     <meta property="quote" content={quote} />
     <meta name="description" content={description} />
     <meta property="image" content={image} />
     <meta property="og:locale" content="en_US" />
     <meta property="og:type" content="website" />
     <meta property="og:title" content={title} />
     <meta property="og:quote" content={quote} />
     <meta property="og:image" content={image} />
     <meta content="image/*" property="og:image:type" />
     <meta property="og:url" content={currentUrl} />
     <meta property="og:site_name" content="Win365 Sports" />
     <meta property="og:description" content={description} />    
     </Helmet>
);
}